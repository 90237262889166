import React, { FC } from 'react'
import Notification from '../Notification'
import styles from './AddToCardNotification.module.scss'
import Image from '../../Image/Image'
import CartIcon from '../../../../public/static/icons/cart.svg'
import cx from 'classnames'

interface IAddToCardNotification {
  onDelete: () => void
  image: string
  title
  variant?: {
    image: string
    title: string
  }
}

const AddToCardNotification: FC<IAddToCardNotification> = ({
  onDelete,
  image,
  title,
  variant,
}) => {
  return (
    <Notification onDelete={onDelete}>
      <div className={styles.container}>
        <Image src={image} width={100} height={120} />
        <div className={styles.content}>
          <div className={styles.add}>
            <CartIcon /> Добавлено в корзину
          </div>
          <p className={styles.title}>{title}</p>
          {variant && (
            <div className={styles.variant}>
              <Image
                className={styles.variantImage}
                src={variant.image}
                width={24}
                height={24}
              />
              {variant.title}
            </div>
          )}
        </div>
      </div>
      <div className={cx(styles.add, styles['add--mobile'])}>
        <CartIcon /> Товар добавлен в корзину
      </div>
    </Notification>
  )
}

export default AddToCardNotification
