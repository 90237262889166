import React, { FC, useEffect, useState } from 'react'
import styles from './Notification.module.scss'
import cn from 'classnames'
import CloseIcon from '../../../../public/static/icons/close-1.svg'
import { createPortal } from 'react-dom'
import createContainer from '../../../../lib/createContainer'

export interface INotification {
  className?: string
  onDelete: () => void
  timeToDelete?: number
  duration?: number
}

const Notification: FC<INotification> = ({
  children,
  className,
  onDelete,
  timeToDelete = 300,
  duration = 3000,
}) => {
  const [container, setContainer] = useState(null)
  const [isClosing, setIsClosing] = useState(false)

  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(onDelete, timeToDelete)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [isClosing, onDelete])

  useEffect(() => {
    setContainer(
      createContainer({
        id: 'notifyContainer',
        className: styles.rootContainer,
      }),
    )
    setTimeout(() => setIsClosing(true), duration)
  }, [])

  if (container === null) return null

  return createPortal(
    <div
      className={cn(
        styles.container,
        isClosing ? styles.slideOut : styles.slideIn,
        className,
      )}
    >
      {children}
      <button
        type="button"
        onClick={() => setIsClosing(true)}
        className={styles.close}
      >
        <CloseIcon />
      </button>
    </div>,
    container,
  )
}

export default Notification
