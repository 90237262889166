import { useState } from 'react'

const useNotifications = <T extends Record<string, unknown>>(): {
  notifications: (T & { id: string })[]
  addNotification: (data: T) => void
  removeNotification: (id: string) => void
} => {
  const [notifications, setNotifications] = useState<(T & { id: string })[]>([])

  function addNotification(data: T) {
    setNotifications((prev) => [
      ...prev,
      { id: new Date().toISOString(), ...data },
    ])
  }

  function removeNotification(id: string) {
    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id),
    )
  }

  return { notifications, addNotification, removeNotification }
}

export default useNotifications
